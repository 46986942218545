@use '../../../styles/mediaqueries' as *;
@use '../../../styles/colors' as *;

.fullScreen {
  position: relative;
  text-align: center;
}

.fullScreenImage {
  overflow: hidden;

  img {
    width: 100vw;
    position: relative;
    object-fit: cover;
    vertical-align: middle;

    @include media(bigScreen) {
      height: 100vh;
    }
  }

  &Animated {
    img {
      animation: horizontalScrollMobile 40s infinite normal ease-in-out;
      left: -50px;
      transform: scale(1.5);

      @include media(bigScreen) {
        animation: horizontalScroll 30s infinite normal ease-in-out;
        top: 15vh;
        left: -20vw;
        object-position: -20px;
      }
    }
  }
}

.title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: max-content;

  .textTitle,
  .textSubTitle {
    text-shadow: black 1px 0 15px;
  }

  @include media(bigScreen) {
    top: 5%;
    left: 50%;
    transform: translate(-50%);
  }
}

.dropdown {
  display: inline-block;
  cursor: pointer;

  .dropdownContent {
    display: none;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0px);
    background-color: $background;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    padding: 12px 16px;
    z-index: 1;
    gap: 10px;
  }

  &:hover {
    .dropdownContent {
      display: grid;
    }
  }
}

@-webkit-keyframes horizontalScrollMobile {
  0% {
    left: -50px;
  }
  50% {
    left: 0px;
  }
  100% {
    left: -50px;
  }
}
@keyframes horizontalScrollMobile {
  0% {
    left: -20vw;
  }
  50% {
    left: 0px;
  }
  100% {
    left: -20vw;
  }
}

@-webkit-keyframes horizontalScroll {
  0% {
    left: -20vw;
  }
  50% {
    left: 0px;
  }
  100% {
    left: -20vw;
  }
}
@keyframes horizontalScroll {
  0% {
    left: -20vw;
  }
  50% {
    left: 0px;
  }
  100% {
    left: -20vw;
  }
}
