@use './colors' as *;
@use './mediaqueries' as *;

@mixin baseFontColor {
  color: $text-black;
}

@mixin baseFont {
  font-family: 'Montserrat', Arial, Helvetica, sans-serif;
  font-weight: 400;
}

@mixin baseFontSize {
  font-size: 18px;
}

@mixin textBody {
  font-weight: 400;
  line-height: 1.5;
  color: $text-black;
}

@mixin title {
  font-weight: 400;
  margin-bottom: 0.1em;
  color: $text-grey;
  font-family: 'BebasNeue', Arial, Helvetica, sans-serif;
}

@mixin heading {
  font-weight: 400;
  margin-bottom: 0.1em;
  font-family: 'BebasNeue', Arial, Helvetica, sans-serif;
}

.textTitle {
  @include title;
  font-size: 50px;

  @include media(mediumScreen, bigScreen) {
    font-size: 70px;
  }
}

.textSubTitle {
  font-weight: 400;
  margin-bottom: 0.1em;
  font-size: 20px;
  color: $text-grey;
  font-family: 'Montserrat', Arial, Helvetica, sans-serif;

  @include media(mediumScreen, bigScreen) {
    font-size: 28px;
  }
}

.textHeading {
  @include heading;
  font-size: 36px;
}
