@use './colors' as *;
@use './typography' as *;
@use './fonts' as *;
@use './mediaqueries' as *;

* {
  margin: 0;
  padding: 0;
}

a {
  @include baseFont;
  @include textBody;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

body {
  @include baseFont;
  @include baseFontColor;
  @include baseFontSize;
  line-height: 1.2;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: $background;
}
