@use '../../../styles/mediaqueries' as *;

@mixin animateToRight {
  animation: animateToRight 3s normal 300ms ease-in-out;
}

@mixin animateToLeft {
  animation: animateToLeft 3s normal 300ms ease-in-out;
}

.collage {
  height: 100%;
  margin: 50px;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  .imageContainer {
    width: 70vw;
    height: 70vw;

    @include media(bigScreen) {
      width: 100%;
    }
  }

  @include media(bigScreen) {
    display: grid;

    :nth-child(odd) {
      height: 80vh;
    }
    :nth-child(even) {
      height: 60vh;
    }
  }

  :nth-child(odd) {
    @include animateToRight();
  }

  :nth-child(even) {
    @include animateToLeft();
  }
}

@mixin grid {
  background-repeat: no-repeat;
  background-size: cover;
}

.imageContainer {
  @include grid;
  opacity: 0;
}

@keyframes animateToRight {
  0% {
    opacity: 0;
    background-position: -500px 50%;
  }
  80% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
    background-position: 50% 50%;
  }
}

@keyframes animateToLeft {
  0% {
    opacity: 0;
    background-position: 500px 50%;
  }
  80% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
    background-position: 50% 50%;
  }
}
