// Bebas Neue

@font-face {
  font-family: 'BebasNeue';
  font-weight: 400;
  src:
    local('BebasNeue'),
    url(../fonts/BebasNeue/BebasNeue-Regular.ttf) format('truetype');
  font-display: fallback;
}

// Montserrat

@font-face {
  font-family: 'Montserrat';
  font-weight: 700;
  src:
    local('Montserrat'),
    url(../fonts/Montserrat/Montserrat-Bold.ttf) format('truetype');
  font-display: fallback;
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 700;
  font-style: italic;
  src:
    local('Montserrat'),
    url(../fonts/Montserrat/Montserrat-BoldItalic.ttf) format('truetype');
  font-display: fallback;
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 400;
  src:
    local('Montserrat'),
    url(../fonts/Montserrat/Montserrat-Regular.ttf) format('truetype');
  font-display: fallback;
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 400;
  font-style: italic;
  src:
    local('Montserrat'),
    url(../fonts/Montserrat/Montserrat-Italic.ttf) format('truetype');
  font-display: fallback;
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 100;
  src:
    local('Montserrat'),
    url(../fonts/Montserrat/Montserrat-Thin.ttf) format('truetype');
  font-display: fallback;
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 100;
  font-style: italic;
  src:
    local('Montserrat'),
    url(../fonts/Montserrat/Montserrat-ThinItalic.ttf) format('truetype');
  font-display: fallback;
}
